import { Box, Flex, SecondaryButton, Text, VStack } from '@openstore/react-ui'
import { getProviders, signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { OSNextPage } from 'types/app'

export async function getServerSideProps() {
  return {
    props: {
      providers: await getProviders(),
    },
  }
}

const SignIn: OSNextPage<{
  providers: Awaited<ReturnType<typeof getProviders>>
}> = ({ providers }) => {
  const { query } = useRouter()

  const callbackUrl = typeof query.callbackUrl === 'string' ? query.callbackUrl : '/'
  return (
    <Flex flex={1}>
      <Flex flex={1} flexDir="column" alignItems="center" justifyContent="center" px="3" bg="primary.200">
        <VStack spacing="8" mt="-8">
          {query.error && (
            <Box bg="primary.400" py="2" px="4" borderRadius="md">
              <Text textStyle="body1Semibold" color="error.500">
                Sign in failed. Check the details you provided are correct.
              </Text>
            </Box>
          )}
          <Box mb="6">
            <Text as="h1">
              Welcome to{' '}
              <Box as="span" color="primary.800">
                OpenStore
              </Box>
            </Text>
          </Box>

          <VStack spacing="4">
            <SecondaryButton
              width="100%"
              onClick={() =>
                signIn(providers!.okta.id, {
                  callbackUrl,
                })
              }
            >
              Sign in with {providers!.okta.name}
            </SecondaryButton>
          </VStack>
          <Box mt="4">
            <Text textStyle="body2">
              Trouble logging in? Ask in{' '}
              <a href="https://openstore-workspace.slack.com/app_redirect?channel=engineering">
                <strong>#engineering</strong>
              </a>
            </Text>
          </Box>
        </VStack>
      </Flex>
    </Flex>
  )
}

SignIn.showHeader = false

export default SignIn
